
import { defineComponent } from "vue";
import { useHead } from "@vueuse/head";
import { data } from "@/shared/policy/privacy/privacy_v1.0.0";
import PrivacyMd from "@/shared/policy/privacy/privacy_v2.0.0.md";

import Markdown from "vue3-markdown-it";
export default defineComponent({
  name: "Privacy",
  components: {
    Markdown
  },
  data() {
    return {
      source: PrivacyMd
    };
  },

  setup() {
    useHead({ title: data.sitemapTitle });
    return {
      data
    };
  }
});
